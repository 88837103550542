.searchpanel-v3 {

	max-width: 290px;
	border: 1px solid var(--color-grey-normal);

	@media (max-width: 767px) {
		border: 0px solid var(--color-grey-normal);
	}

	.sidebar-search {
		background-color: var(--color-white);
		padding: 5px;
	}

	.search-bar {
		padding: 10px 15px;
		color: var(--color-white);

		@media (min-width:769px) {
			background-color: var(--widget-header-bg-color);
		}

		.title {
			font-size: var(--h4-font-size);
			font-weight: 500;
		}

	}

	.label-li {
		li {
			margin: 3px 0;
			padding: 0;
			font-size: var(--font-size-main);

			.checkbox {
				padding: 0 0 0 5px;
			}
		}
	}

	.search-bar-content {
		padding: 15px;

		.filter-icon {
			position: relative;

			select {
				-webkit-appearance: none;
			}

			.fa {
				position: absolute;
				top: 16px;
				right: 10px;
				font-size: 1rem;
				opacity: 0.5;
				pointer-events: none;

				@media (max-width:992px) {
					top: 10px;
				}
			}
		}

	}

	.sidebar-search {
		@media (min-width:768px) {
			display: block !important;
		}

		.search-m-form {
			@media (min-width:768px) {
				display: none;
			}



			@media (max-width:767px) {
				background-color: var(--darkblue);
				padding: 15px 0;
				position: relative;
				z-index: 3000;
			}
		}
	}

	#searchpanel {
		.container {
			width: auto;
		}

		@media (min-width:768px) {
			position: relative;
			display: block !important;
			overflow: visible;

			.close {
				display: none;
			}

			&.modal {
				z-index: 3 !important;
			}

			&.fade {
				opacity: 1;
			}

			.modal-dialog {
				width: auto;
				-webkit-transform: translateY(0%);
				transform: translateY(0%);
				margin: 0;
				pointer-events: inherit;
			}
		}


		@media (max-width:767px) {
			.search-bar {
				border: 1px solid #eee;
				border-top: none;
			}

			&.modal.fade {
				.modal-dialog {
					-webkit-transform: translateX(-100%);
					transform: translateX(-100%);
					position: fixed !important;
				}

				&.show {
					.modal-dialog {
						-webkit-transform: translateX(0);
						transform: translateX(0);
						pointer-events: inherit;
					}
				}
			}
		}

		.modal-dialog {
			@media (max-width:767px) {
				margin: 0;
				padding: 50px 15px 15px;
				z-index: 20001;
				max-width: 650px;
				width: 100%;
				background-color: var(--color-grey-light);
				overflow-y: scroll;
				height: 100vh;

				>.close {
					padding: 0 8px 1px;
					position: absolute;
					top: 6px;
					right: 16px;
					z-index: 50;
					opacity: 1;
					font-size: 36px;
				}
			}
		}
	}

	.form-bx-area,
	.datepicker-trigger,
	.form-group {
		position: relative;

		.fa {
			position: absolute;
			right: 1rem;
			bottom: 18px;
			color: var(--color-grey-dark);
			font-size: 22px;
			display: block;
			line-height: 21px;

			@media (max-width: 992px) {
				font-size: 16px;
				bottom: 13px;
				right: 10px;
			}
		}

	}


	.form-control {
		border-radius: 3px;
		font-size: var(--font-size-main);
		cursor: pointer;
		height: auto;

		@media (min-width:1200px) {
			font-size: var(--font-size-main);
			padding: 14px 1rem;
			min-height: 55px;
		}

		/*	@media (max-width:1199px) {
			font-size: 13px;
			height: 55px;
		} */

		@media (max-width:992px) {
			font-size: var(--font-size-main);
			height: auto;

		}


		&.btn-lg {

			font-size: 16px;

			@media (min-width:1200px) {
				font-size: 16px;
				padding: 14px 1rem;
				min-height: 55px;
			}

			/*	@media (max-width:1199px) {
				font-size: 13px;
				height: 55px;
			} */

			@media (max-width:992px) {
				font-size: 16px;
				height: auto;
			}

		}

	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}

}